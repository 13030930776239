import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormCheck, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import RDatePicker from "react-datepicker";
// import RcTable from "rc-table";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "@components/Notification";
import PaginationComp from "@components/PaginationComp";
import Header from "@layouts/Header";
import { DateTime } from "luxon";
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import { confirmCvRequest, loadCvDetailRequest, rejectCvRequest, setupInterviewRequest } from "@services/caregiverService";
import { listCvRequest } from "@services/userService";

export default function CareGiverCvManagement(){
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [cvs, setCvs] = useState([]);
    const [cv, setCv] = useState({});//cv detail
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [totalItem, setTotalItem] = useState(0);
    const [status, setStatus] = useState("all");
    const [showCvDetailDialog, setShowCvDetailDialog] = useState(false);
    const [calendar, setCalendar] = useState(new Date());
    const [showRejectAlert, setShowRejectAlert] = useState(false);
    const [showInterviewCalendar, setShowInterviewCalendar] = useState(false);    

    const [confirmDialog, setConfirmDialog] = useState(false);

    const closeConfirmDialog = async() => {
        setConfirmDialog(false);
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await listCvs()
        }
    }
    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }
    useEffect(() => {
        listCvs();
    }, [pageIndex]);
    const listCvs = async() => {
        let fDate = new Date(fromDate);  
        fDate.setHours(0, 0, 0)
        let tDate = new Date(toDate);
        tDate.setHours(23, 59, 59)
        const data = await listCvRequest(status, fDate, tDate, pageIndex, pageSize);
        if(data.isError){
            displayNotification("Can not list CVs", "danger");
            return;
        }
        let items = [];
        setTotalItem(data.total);
        for(let i = 0; i < data.items.length; i++){
            let date = DateTime.fromISO(data.items[i].createdAt);
            items.push({
                stt: i + pageIndex + 1,
                phoneNumber: data.items[i].phoneNumber,
                email: data.items[i].email,
                createdAt: date.toISODate(),
                action: data.items[i].action,
                key: data.items[i].id,
                name: data.items[i].name
            })
        }
        setCvs(items);
    }
    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }
    const onStatusChange = (event) => {
        if(event.target.checked){
            setStatus(event.target.value)
        }
    }
    const onCloseShowCvDialog = async() => {
        await listCvs();
        setShowCvDetailDialog(false);
    }
    const onCloseInterviewCalendar = () => {
        setShowInterviewCalendar(false);
    }
    const onOpenShowCvDialog = async(cvId) => {
        await loadCvDetail(cvId)
        setShowCvDetailDialog(true)
    }
    const loadCvDetail = async(cvId) => {
        const data = await loadCvDetailRequest(cvId);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setCv(data);
    }
    const rejectCv = async() => {
        const data = await rejectCvRequest(cv.id);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setShowRejectAlert(false);
    }
    const confirmCv = async() => {
        const data = await confirmCvRequest(cv.id)
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeConfirmDialog();
    }
    const setupInterview = async() => {
        let d = (DateTime.fromJSDate(calendar)).toISO();   
        const data = await setupInterviewRequest(cv.id, d);
        console.log(data);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        onCloseInterviewCalendar();
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row>
                        <Col md={1}>
                            From:
                        </Col>
                        <Col md={2} style={{textAlign: "left"}}>
                            <DatePicker value={fromDate} onChange={setFromDate}/> 
                        </Col>
                        <Col md={1}>
                            To:
                        </Col>
                        <Col md={2} style={{textAlign: "right"}}>
                            <DatePicker value={toDate} onChange={setToDate}/> 
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={1}>
                            CV's status:
                        </Col>
                        <Col md={4}>
                            <FormCheck onChange={onStatusChange} type="radio" inline value="all" label="All" defaultChecked name="action-group"/>
                            <FormCheck onChange={onStatusChange} type="radio" inline value="new" label="New" name="action-group"/>
                            <FormCheck onChange={onStatusChange} type="radio" inline value="rejected" label="Rejected" name="action-group"/>
                            <FormCheck onChange={onStatusChange} type="radio" inline value="interview" label="Interviewing" name="action-group"/>
                        </Col>
                        <Col md={2} style={{textAlign: "right"}}>
                            <Button variant="primary" onClick={listCvs}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={12}>
                            {/* <RcTable columns={columns} data={cvs} /> */}
                            <table className="table table-bordered table-hover table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>Full Name</th>
                                        {/* <th>Email</th> */}
                                        <th>Phone</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th style={{textAlign: "center"}}>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cvs &&
                                        <>
                                            {cvs.map((cv) => {
                                                return (
                                                   <tr key={cv.key}>
                                                        <td>
                                                            {cv.stt}
                                                        </td>
                                                        <td>
                                                            {cv.name}
                                                        </td>
                                                        {/* <td>
                                                            {cv.email}
                                                        </td> */}
                                                        <td>
                                                            {cv.phoneNumber}
                                                        </td>
                                                        <td>
                                                            {cv.action}
                                                        </td>
                                                        <td>
                                                            {cv.createdAt}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <FontAwesomeIcon icon={faEye} size="lg" onClick={e => onOpenShowCvDialog(cv.key)}/>
                                                        </td>
                                                   </tr>
                                                )
                                            })}
                                        </>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={showCvDetailDialog} onHide={onCloseShowCvDialog} backdrop="static" size="xl">
                <ModalHeader closeButton>
                    <h4>Hồ sơ ứng viên</h4>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={6} style={{fontWeight: "bold"}}>
                                <h5>Thông tin cơ bản</h5>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Họ tên:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.name}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Số điện thoại:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.phoneNumber}
                            </Col>
                        </Row>
                        {/* <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Email:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.email}
                            </Col>
                        </Row> */}
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Ngày sinh:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {DateTime.fromISO(cv.dob).toISODate()}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Quê quán:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.address}
                            </Col>
                        </Row>
                        {/* <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Số CCCD:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.IDNumber}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Ngày cấp:</Col>
                            <Col md={3} style={{color: "red"}}>
                                {cv.IDNumberIssuedDate}
                            </Col>
                            <Col md={3}>Nơi cấp:</Col>
                            <Col md={3} style={{color: "red"}}>
                                {cv.IDNumberIssuedPlace}
                            </Col>
                        </Row> */}
                        {/* <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Kinh nghiệm:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.expSharing}
                            </Col>
                        </Row> */}
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Loại công việc đăng ký:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv?.package?.packageName}
                            </Col>
                        </Row>
                        {/* <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Hình thức hợp tác:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.cooperateType}
                            </Col>
                        </Row> */}
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Số giờ cung cấp dịch vụ (giờ):</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.workingTime}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10}>
                                <hr/>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={6} style={{fontWeight: "bold", marginTop: "10px"}}>
                                <h5>Kiểm tra năng lực</h5>
                            </Col>
                        </Row> */}
                        <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Kinh nghiệm chăm sóc:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.expSharing}
                            </Col>
                        </Row>
                        {/* <Row style={{marginTop: "5px"}}>
                            <Col md={3}>Hồ sơ đính kèm:</Col>
                            <Col md={7}>
                                <table className="table table-bordered table-hover table-striped table-responsive">
                                    <thead>
                                        <tr  style={{fontWeight: "bold"}}>
                                            <td>Document's name</td>
                                            <td>Document's type</td>
                                            <td style={{textAlign: "center"}}>Download</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cv.Documents &&
                                            <>
                                                {cv.Documents.map((doc) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{doc.documentName}</td>
                                                                <td>{mime.getExtension(doc.documentType)}</td>
                                                                <td style={{textAlign: "center"}}>
                                                                    <FontAwesomeIcon icon={faDownload} />
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col md={10}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={7}>Bạn có các chứng chỉ liên quan đến điều dưỡng/y tá, hoặc chăm sóc sức khỏe/hoặc chăm sóc người cao tuổi không?*:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.hasCertificate ? "Có " : "Không có "}
                            </Col>
                        </Row>
                        {/* <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Kinh nghiệm:</Col>
                        </Row> */}
                        <Row>
                            <Col md={10}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={7}>Bạn có sử dụng điện thoại thông minh không?*:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.hasSmartphone ? "Có " : "Không có "}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={7}>Bạn có sử dụng các ứng dụng như Zalo không?*:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.hasZalo ? "Có " : "Không có "}
                            </Col>
                        </Row>
                        {/* <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Kỹ năng:</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Bạn có thoải mái với việc cung cấp hỗ trợ cho các hoạt động sinh hoạt hàng ngày như tắm rửa, mặc quần áo, đi vệ sinh và cho ăn không?:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.canSkil1 ? "Có" : "Không"}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Kinh nghiệm quản lý thuốc hoặc sử dụng thiết bị y tế cụ thể:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.canSkil2 ? "Có" : "Không"}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Thời gian:</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Số tiếng làm việc trong ngày:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.workHourPerDay}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Sẵn sàng làm việc ca đêm hoặc vào cuối tuần:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.canOverTime ? "Có" : "Không"}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10}>
                                <hr/>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} style={{fontWeight: "bold", marginTop: "10px"}}>
                                <h5>Xử lý tình huống</h5>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Trường hợp khẩn cấp</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Nếu một người lớn tuổi dưới sự chăm sóc của bạn bị ngã và không thể đứng dậy, bạn sẽ thực hiện những bước nào:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.urgentCaseAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Khủng hoảng sức khỏe:</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Bạn sẽ phản ứng như nào nếu người lớn tuổi do bạn chăm sóc đột nhiên kêu đau ngực hoặc có dấu hiệu đột quỵ:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.healthCrisisAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Các vấn đề về cảm xúc:</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Nếu người mà bạn đang chăm sóc trở nên hung hăng hoặc khó gần, bạn sẽ xử lý tình huống như thế nào:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.feelingProblemAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Các vấn đề liên quan tới chứng mất trí nhớ</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Nếu một người mắc chứng mất trí nhớ dưới sự chăm sóc của bạn trở nên bối rối và mất phương hướng, từ chối nhận ra bạn, bạn sẽ xử lý tình huống như nào</Col>
                            <Col md={5 } style={{color: "red"}}>
                                {cv.lossOfMemoryAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Quản lý việc uống thuốc</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Bạn nhận ra rằng người dưới sự chăm sóc của bạn đã bỏ lỡ thuốc của họ, bạn sẽ làm gì:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.medicineUsageAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Khó khăn trong việc ăn uống:</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Người dưới sự chăm sóc của bạn không chịu ăn. Bạn sẽ khuyến khích họ dùng bữa như thế nào?</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.feedingProblemAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Tai nạn khi đi vệ sinh:</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Người lớn tuổi do bạn chăm sóc bị tai nạn và không thể tự đi vệ sinh nhưng lại cảm thấy xấu hổ khi đi vệ sinh tại chỗ. Bạn sẽ xử lý tình huống này như nào?</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.accidentAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Thiếu giao tiếp</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Nếu người cao tuổi dưới sự chăm sóc của bạn không phản hồi hoặc không truyền đạt nhu cầu của họ một cách rõ ràng, bạn sẽ đảm bảo sức khỏe của họ như thế nào?</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.communicationProblemAnswer}
                            </Col>
                        </Row>
                        <Row style={{marginTop: "5px", fontWeight: "bold"}}>
                            <Col md={3}>Quan hệ gia đình</Col>
                        </Row>
                        <Row style={{marginTop: "5px"}}>
                            <Col md={5}>Các thành viên trong gia đình có ý kiến khác nhau về cách thức chăm sóc người thân của họ. Bạn sẽ làm gì trong tình huống này:</Col>
                            <Col md={5} style={{color: "red"}}>
                                {cv.familyRelationAnswer}
                            </Col>
                        </Row> */}
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {cv.action !== "accepted" &&
                        <Button variant="warning" onClick={e => setConfirmDialog(true)}>Confirm</Button>
                    }
                    {cv.action !== "rejected" &&
                        <Button variant="danger" onClick={e => setShowRejectAlert(true)}>Reject</Button>
                    }
                    
                    <Button variant="success" onClick={e => setShowInterviewCalendar(true)}>Setup Call</Button>
                    <Button variant="secondary" onClick={onCloseShowCvDialog}>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal show={showRejectAlert} onHide={e => setShowRejectAlert(false)} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>Alert</ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={12}>
                                Do you want to reject this CV?
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={rejectCv}>Yes</Button>
                    <Button variant="primary" onClick={e => setShowRejectAlert(false)}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal show={confirmDialog} onHide={e => setConfirmDialog(false)} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>Alert</ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={12}>
                                Do you want to confirm this CV?
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={confirmCv}>Yes</Button>
                    <Button variant="primary" onClick={closeConfirmDialog}>No</Button>
                </ModalFooter>
            </Modal>
            <Modal show={showInterviewCalendar} onHide={onCloseInterviewCalendar} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}></ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md={4}>
                                Choose calendar:
                            </Col>
                            <Col md={5}>
                                <RDatePicker selected={calendar} onChange={(date) => setCalendar(date)} 
                                showTimeSelect minDate={new Date()}
                                timeFormat="HH:mm"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                /> 
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={setupInterview}>Yes</Button>
                    <Button variant="primary" onClick={onCloseInterviewCalendar}>No</Button>
                </ModalFooter>
            </Modal>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
        </React.Fragment>
    )
}