import FormProvider from "@components/hook-form/form-provider";
import RHFCheckbox from "@components/hook-form/rhf-checkbox";
import RHFDatePicker from "@components/hook-form/rhf-date-picker";
import RHFSelect from "@components/hook-form/rhf-select";
import RHFTextField from "@components/hook-form/rhf-text-field";
import Notification from "@components/Notification";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@hooks/useBoolean";
import { getCities } from "@services/calendarService";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { Card, Form, Stack } from "react-bootstrap";

import { orderBy, map } from "lodash";
import { listAllPackageRequest } from "@services/packageService";
import SelectUser from "../components/SelectUser";
import { createCalendarRequest } from "@services/calendarService";
import { userDetailRequest } from "../../../../services/userService";
import { fCurrency } from "../../../../utils/format-number";

export const CITIES = ["Hồ Chí Minh", "Bà Rịa-Vũng Tàu", "Hà Nội"];

const defaultValues = {
  familyMemberId: "",
  addressType: "home",
  city: CITIES[0], // Hồ Chí Minh
  district: "",
  street: "",
  service: "",
  serviceType: "",
  note: "",
  bookingDate: new Date(),
  bookingHour: 1,
  paymentStatus: "PENDING",
};

const bookingSchema = Yup.object().shape({
  familyMemberId: Yup.string().required("Bắt buộc Chọn người hưởng"),
  city: Yup.mixed().required("Bắt buộc nhập thành phố"),
  district: Yup.string().required("Bắt buộc nhập quận/huyện"),
  street: Yup.string().required("Bắt buộc nhập số nhà/đường"),
  service: Yup.string().required("Bắt buộc chọn dịch vụ"),
  serviceType: Yup.string().required("Bắt buộc chọn loại dịch vụ"),
  note: Yup.string(),
  bookingDate: Yup.date().required("Bắt buộc chọn ngày"),
  bookingHour: Yup.number()
    .integer("Vui lòng nhập số nguyên")
    .min(1, "Vui lòng nhập số lớn hơn 0")
    .required("Bắt buộc chọn giờ"),
});

const AddCalendar = (props) => {
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [cities, setCities] = useState([]);
  const [packages, setPackages] = useState([]);

  const isShowSelectUser = useBoolean(false);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(bookingSchema),
  });

  const {
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const values = watch();

  // const packageItems = packageItemsData.filter((item) => item.itemStatus === 'actived-ready');

  const citiesFiltered = orderBy(
    cities.filter((city) => CITIES.includes(city.cityName)),
    ["cityName"],
    ["asc"]
  );

  const districts = useMemo(() => {
    return (
      orderBy(
        cities.find((city) => city.cityName === values.city)
          ?.districtCategories,
        ["districtName"],
        ["asc"]
      ) || []
    );
  }, [cities, values.city]);

  const showToast = useBoolean(false);

  const showMessageToast = useCallback(
    (msg, variant) => {
      showToast.onTrue();
      setToastContent(msg);
      setToastVariant(variant);
    },
    [showToast]
  );

  useEffect(() => {
    getCities().then((cities) => {
      if (cities.isError) {
        showMessageToast("Failed to get cities", "danger");
        return;
      }
      setCities(cities?.items || []);
    });
    listAllPackageRequest().then((packages) => {
      if (packages.isError) {
        showMessageToast("Failed to get packages", "danger");
        return;
      }
      setPackages(packages.items);
    });
  }, []);

  const { handleSubmit } = methods;

  const service = watch("service");
  const serviceType = watch("serviceType");
  const bookingHour = watch("bookingHour");
  const familyMember = watch("familyMember");

  const packageItems = useMemo(
    () => packages.find((item) => item.id === service)?.packageItems || [],
    [packages, service]
  );

  const unitService = packageItems.find(
    (item) => item.id === serviceType
  )?.unit;

  useEffect(() => {
    if (packages.length === 0) return;
    methods.setValue("service", packages[0]?.id || "");
  }, [methods, packageItems, packageItems.length, packages]);

  useEffect(() => {
    if (packageItems.length === 0) return;
    methods.setValue("serviceType", packageItems[0]?.id || "");
  }, [methods, packageItems, packageItems.length]);

  useEffect(() => {
    if (districts.length === 0) return;
    methods.setValue("district", districts[0]?.districtName || "");
  }, [districts, methods]);

  const onSelectUser = useCallback(
    (user) => {
      setValue("familyMember", user);
    },
    [setValue]
  );

  const submit = async (values) => {
    const bookingData = {
      familyMemberId: values.familyMemberId,
      addressType: values.addressType,
      address: `${values.street}, ${values.district}, ${values.city}`,
      packageItemId: values.serviceType,
      note: values.note,
      datetime: values.bookingDate,
      length: +values.bookingHour,
      paymentStatus: values.paymentStatus,
    };

    const res = await createCalendarRequest(bookingData);

    if (res.isError) {
      showMessageToast("Failed to create", "danger");
      return;
    }

    showMessageToast("Create successfully", "success");
  };

  const totalPrice =
    (packageItems.find((item) => item.id === serviceType)?.price || 0) *
    +bookingHour;

  useEffect(() => {
    if (familyMember?.id) {
      userDetailRequest(familyMember.id).then((res) => {
        if (res.isError) {
          showMessageToast("Failed to get family member", "danger");
          return;
        }
        setValue("familyMemberId", res?.familyMembers?.[0]?.id);
      });
    }
  }, [familyMember?.id]);

  return (
    <div className="main main-docs">
      <div className="container">
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
          <Container>
            <label className="main-title-label">Calendar</label>
            <h2 className="main-title">Add new calendar</h2>

            <Card className="my-5">
              <Card.Body>
                <Card.Title>Thông tin người hưởng:</Card.Title>
                {familyMember && (
                  <div className="my-3">
                    <p>
                      Người được hưởng:{" "}
                      <strong>{familyMember?.fullName}</strong>
                    </p>
                    <p>
                      Số điện thoại:{" "}
                      <strong>{familyMember?.phoneNumber}</strong>
                    </p>
                  </div>
                )}
                <Button
                  variant="outline-primary"
                  onClick={isShowSelectUser.onTrue}
                  size="sm"
                >
                  Chọn người được hưởng
                </Button>
                {errors.familyMemberId && (
                  <div className="text-danger">
                    {errors.familyMemberId.message}
                  </div>
                )}
              </Card.Body>
            </Card>

            <div className="my-5">
              <h5 className="main-subtitle">Thông tin địa chỉ:</h5>
              <Row className="align-items-center my-4">
                <Col sm={6}>
                  <RHFCheckbox
                    name="addressType"
                    type="radio"
                    label="Home"
                    value="home"
                  />
                </Col>
                <Col sm={6}>
                  <RHFCheckbox
                    name="addressType"
                    type="radio"
                    label="Medical facility"
                    value={"medical-facility"}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6}>
                  <Form.Label>Tỉnh/ Thành phố:</Form.Label>
                  <RHFSelect name="city">
                    {map(citiesFiltered, (city) => (
                      <option key={city.id} value={city.cityName}>
                        {city.cityName}
                      </option>
                    ))}
                  </RHFSelect>
                </Col>
                <Col md={6} className="mt-4 mt-md-0">
                  <Form.Label>Huyện/ Quận:</Form.Label>
                  <RHFSelect name="district">
                    {map(districts, (district) => (
                      <option key={district.id} value={district.districtName}>
                        {district.districtName}
                      </option>
                    ))}
                  </RHFSelect>
                </Col>
              </Row>

              <Row className="align-items-center mt-4">
                <Col md={12}>
                  <Form.Label>Tên đường:</Form.Label>
                  <RHFTextField name="street" />
                </Col>
              </Row>
            </div>

            <div className="my-5">
              <h5 className="main-subtitle">Thông tin dịch vụ:</h5>

              <Row className="align-items-center mt-4">
                <Col md={12}>
                  <Form.Label>Thời gian:</Form.Label>{" "}
                  <RHFDatePicker name="bookingDate" />
                </Col>
              </Row>

              <Row className="align-items-center mt-4">
                <Col md={12}>
                  <Form.Label>Dịch vụ:</Form.Label>
                  <RHFSelect name="service">
                    {map(packages, (item) => (
                      <option key={item.id} value={item.id}>
                        {item.packageName}
                      </option>
                    ))}
                  </RHFSelect>
                </Col>
              </Row>

              {service && (
                <Row className="align-items-center mt-4">
                  <Col md={12}>
                    <Form.Label>Loại dịch vụ:</Form.Label>
                    <RHFSelect name="serviceType">
                      {map(packageItems, (item) => (
                        <option key={item.id} value={item.id}>
                          {item.itemName}
                        </option>
                      ))}
                    </RHFSelect>
                  </Col>
                </Row>
              )}

              {unitService === "hours" && (
                <Row className="align-items-center mt-4">
                  <Col md={12}>
                    <Form.Label>Thời gian chăm sóc:</Form.Label>
                    <RHFSelect name="bookingHour">
                      <option value="1">1 giờ</option>
                      <option value="2">2 giờ</option>
                      <option value="3">3 giờ</option>
                      <option value="4">4 giờ</option>
                      <option value="5">5 giờ</option>
                      <option value="6">6 giờ</option>
                      <option value="7">7 giờ</option>
                      <option value="8">8 giờ</option>
                    </RHFSelect>
                  </Col>
                </Row>
              )}

              {unitService === "days" && (
                <Row className="align-items-center mt-4">
                  <Col md={12}>
                    <Form.Label>Số ngày cần chăm sóc*:</Form.Label>
                    <RHFTextField name="bookingHour" type="number" />
                  </Col>
                </Row>
              )}

              <Row className="align-items-center mt-4">
                <Col md={12}>
                  <Form.Label>Trạng thái thanh toán:</Form.Label>
                  <RHFSelect name="paymentStatus">
                    <option value="PAID">Đã thanh toán</option>
                    <option value="PENDING">Chưa thanh toán</option>
                  </RHFSelect>
                </Col>
              </Row>

              <Row className="align-items-center mt-4">
                <Col md={12}>
                  <Form.Label>Ghi chú:</Form.Label>
                  <RHFTextField name="note" as="textarea" />
                </Col>
              </Row>

              <Stack direction="horizontal" gap={3} className="my-5">
                <div>
                  <Form.Label>Tổng cộng: </Form.Label>
                  <h5 className="text-danger">{fCurrency(totalPrice)}</h5>
                </div>

                <Button variant="primary" type="submit" className="ms-auto">
                  Submit
                </Button>
              </Stack>
            </div>
          </Container>
        </FormProvider>
        <Notification
          showToast={showToast.value}
          toastContent={toastContent}
          toastVariant={toastVariant}
          closeNotification={showToast.onFalse}
        />
        <SelectUser
          open={isShowSelectUser.value}
          onClose={isShowSelectUser.onFalse}
          onSelect={onSelectUser}
        />
      </div>
    </div>
  );
};

export default memo(AddCalendar);
