import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Col,
  Container,
  Row,
  Table
} from "react-bootstrap";
import Header from "@layouts/Header";

const UserTotalBooking = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={12}></Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">User management</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">User detail: ...</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  User total booking
                </li>
              </ol>
            </nav>
          </Row>

          <Row>
            <Col style={{textAlign: "right"}}>
              <button type="button" class="btn btn-primary">
                Add New Booking
              </button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md={12}>
              <Table bordered striped>
                <thead className="table-primary">
                  <tr style={{ fontWeight: "bold" }}>
                    <th>No</th>
                    <th>Booking Number</th>
                    <th>Booking Time</th>
                    <th>Status</th>
                    <th>Family Member</th>
                    <th>Service Types</th>
                    <th>Final Price</th>
                    <th style={{ textAlign: "center" }}>View/Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>001-B01</td>
                    <td>2023-09-09 23:11:11</td>
                    <td>Finished</td>
                    <td>Hòa (Ông)</td>
                    <td>Pay as Hours</td>
                    <td>200.000đ</td>
                    <td style={{ textAlign: "center" }}>
                      <a href="#">
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          {/* <Row>
            <Col md={2}></Col>
            <Col md={4}>
              <PaginationComp
                total={totalItem}
                pageSize={pageSize}
                callback={handlePaginationCallback}
              />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserTotalBooking;
