import React, { useEffect } from "react";
import Header from "@layouts/Header";
import { Button, Col, Container, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "react-bootstrap";
import { useState } from "react";
import { ClockLoader } from "react-spinners";
import Notification from "@components/Notification";
import PaginationComp from "@components/PaginationComp";
import { listUserByRoleRequest, updateUserRequest } from "@services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKey } from "@fortawesome/free-solid-svg-icons";

export default function EmployeeManagement(){
    const [employees, setEmployees] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [totalItem, setTotalItem] = useState(0);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false)

    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [selectedUser, setSelectedUser] = useState({});

    const closeResetPasswordDialog = () => {
        setResetPasswordDialog(false);
    }


    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
        setLoading(false)
    }

    const handlePaginationCallback = (size, index) => {
        setPageSize(size);
        setPageIndex(index);
    }

    const listEmployee = async() => {
        setLoading(true)
        const data = await listUserByRoleRequest("admin", email, phone, pageIndex, pageSize)
        if(data.isError){
            displayNotification(data.msg, "danger");
            return
        }
        setLoading(false);
        let items = []
        for(let i = 0; i < data.items.length; i++){
            let roleStatus = true;
            if(data.items[i].Roles){
                for(let j = 0; j < data.items[i].Roles.length; j++){
                    if(data.items[i].Roles[j].roleName === "admin"){
                        roleStatus = data.items[i].Roles[j].UserRole.isActive
                    }
                }
            }
            items.push({
                id: data.items[i].id,
                phoneNumber: data.items[i].phoneNumber,
                email: data.items[i].email,
                fullName: data.items[i].fullName,
                status: roleStatus
            })
        }
        setEmployees(items);
        setTotalItem(data.total);
    }

    const changePassword = async() =>{
        if(password === ""){
            displayNotification("Password can not be empty", "danger");
            return;
        }
        if(password !== repassword){
            displayNotification("Passwords are not matched", "danger");
            return;
        }
        let params = {
            password: password
        }
        const data = await updateUserRequest(params, selectedUser.id);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        closeResetPasswordDialog();
    }

    useEffect(() => {
        listEmployee()
    }, [pageIndex]);


    return(
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2}>
                            User Email:
                        </Col>
                        <Col md={4}>
                            <FormControl type="text" onChange={e => setEmail(e.target.value)}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2}>
                            User Phone:
                        </Col>
                        <Col md={4}>
                            <FormControl type="text" onChange={e => setPhone(e.target.value)}/>
                        </Col>
                        <Col md={2} style={{textAlign: "left"}}>
                            <Button variant="primary" onClick={e => {
                                if(pageIndex !== 0){
                                    setPageIndex(0)
                                }else{
                                    listEmployee()
                                }
                            }}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>
                            Employee List:
                        </Col>
                        <Col md={10} style={{textAlign: "right", fontWeight: "bold"}}>
                            Total: {totalItem}
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Full Name</th>
                                        <th>Status</th>
                                        <th style={{textAlign: "center"}}>Edit</th>
                                        <th style={{textAlign: "center"}}>Change Password</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employees && 
                                        <>
                                            {employees.map((employee) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {employee.phoneNumber}
                                                        </td>
                                                        <td>
                                                            {employee.email}
                                                        </td>
                                                        <td>
                                                            {employee.fullName}
                                                        </td>
                                                        <td>
                                                            {employee.status ? "Active" : "InActive"}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <FontAwesomeIcon icon={faEdit} size="lg" />
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <FontAwesomeIcon icon={faKey} size="lg" onClick={e => {
                                                                setSelectedUser(employee)
                                                                setResetPasswordDialog(true)
                                                            }}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={resetPasswordDialog} onHide={closeResetPasswordDialog} backdrop="static">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>Change user password</ModalHeader>
                <ModalBody>
                    <Container fluid>
                        <Row>
                            <Col md={4}>
                                Enter password:
                            </Col>
                            <Col md={6}>
                                <FormControl type="password" onChange={e => setPassword(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "20px"}}>
                            <Col md={4}>
                                Re-enter password:
                            </Col>
                            <Col md={6}>
                                <FormControl type="password" onChange={e => setRepassword(e.target.value)}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="danger" onClick={changePassword}>Change</Button>
                    <Button variant="secondary" onClick={closeResetPasswordDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
            <div
                style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 9999,
                }}
            >
                <ClockLoader loading={loading} color="red" />
            </div>
        </React.Fragment>
    )
}