import FormBuilderComp from "@components/FormBuilderComp";
import Notification from "@components/Notification";
import PaginationComp from "@components/PaginationComp";
import {
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "@layouts/Header";
import { getCvTemplateRequest, listCvTemplateRequest } from "@services/cvService";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormCheck,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

export default function CvTemplateManagement() {
  
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");   
  const [toastVariant, setToastVariant] = useState("success");
  const [totalItem, setTotalItem] = useState(0);
  const [templateName, setTemplateName] = useState("")
  const [status, setStatus] = useState("all");
  const [templates, setTemplates] = useState([])

  const [cvDialog, setCvDialog] = useState(false);
  const formBuilderCompRef = useRef(null);
  const [cvAction, setCvAction] = useState("new");
  const [cvTemplate, setCvTemplate] = useState({});
  const [cvTemplateId, setCvTemplateId] = useState("");
  const [cvTemplateName, setCvTemplateName] = useState("");

  const closeCvDialog  = async() => {
    setCvDialog(false);
    setCvTemplate({})
    setCvTemplateName("");
    setCvAction("new")
    if(pageIndex !== 0){
      setPageIndex(0)
    }else{
      await searchTemplate()
    }
  }

  const displayNotification = (content, variant) => {
    setToastVariant(variant);    
    setToastContent(content);
    setShowToast(true);
  }
  const closeNotification  = () => {
      setShowToast(false);
  }
  const handlePaginationCallback = async (pageSize, pageIndex) => {        
    setPageIndex(pageIndex);
  }

  const searchTemplate = async() => {
    const data = await listCvTemplateRequest(templateName, status, pageIndex, pageSize);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setTotalItem(data.total);
    setTemplates(data.items)
  }

  const cvActionHandler = async()=>{
    let data = null;
    if(cvAction === "new"){
      if(cvTemplateName === ""){
        displayNotification("Template name must not be empty", "danger");
        return;
      }
      data = await formBuilderCompRef.current.saveForm(cvTemplateName)
    }else{
      data = await formBuilderCompRef.current.updateForm(cvTemplateId, cvTemplateName);
    }

    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    closeCvDialog()
  }
  const getTemplate = async(id) => {
    const data = await getCvTemplateRequest(id);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setCvTemplate(data.template);
    setCvTemplateName(data.templateName)
    setCvDialog(true);
    setCvAction("edit");
  }

  useEffect(() => {
    searchTemplate()
  }, [pageIndex])

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row style={{ marginTop: "10px" }}>
            <Col md={2}>Seach Template:</Col>
            <Col md={4}>
              <FormControl type="text" placeholder="Template name" onChange={e => setTemplateName(e.target.value)}/>
            </Col>
            <Col md={2}>
              <Button variant="success" onClick={e => {
                if(pageIndex !== 0){
                  setPageIndex(0)
                }else{
                  searchTemplate()
                }
              }}>Search</Button>
            </Col>
          </Row>

          <Row style={{ marginTop: "30px" }}>
            <Col md={2}>Filter:</Col>
            <Col md={8}>
                <FormCheck onChange={e => setStatus(e.target.value)}  type="radio" value="all" label="All" inline defaultChecked name="template-status"/>
                <FormCheck onChange={e => setStatus(e.target.value)}  type="radio" value="active" label="Actived" inline  name="template-status"/>
                <FormCheck onChange={e => setStatus(e.target.value)}  type="radio" value="deactive" label="Deactived" inline  name="template-status"/>
            </Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={10}></Col>
            <Col md={2} style={{ textAlign: "right" }}>
              <Button variant="primary" onClick={e => {
                setCvAction("new")
                setCvDialog(true)
              }}>Add new template</Button>
            </Col>
          </Row>

          <Row>
            <Col md={2} className="fw-bold">
              Total: 
            </Col>
            <Col md={10}></Col>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <Table bordered striped responsive>
                <thead className="table-primary">
                  <tr>
                    <th>Created at</th>
                    <th>Template id</th>
                    <th>Template name</th>
                    <th>Status</th>
                    <th style={{ textAlign: "center" }} >View/Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {templates.length <=0 &&
                    <tr>
                      <td colSpan={5} style={{textAlign: "center"}}>
                        No data
                      </td>
                    </tr>
                  }
                  {templates && 
                    <>
                      {templates.map((template) => {
                        return (
                          <tr>
                            <td>{template.createdAt}</td>
                            <td>{template.id}</td>
                            <td>{template.templateName}</td>
                            <td>{template.templateStatus ? "Active" : "Inactive"}</td>
                            <td style={{textAlign: "center"}}>
                              <FontAwesomeIcon icon={faEdit} onClick={e => {
                                setCvTemplateId(template.id);
                               getTemplate(template.id)
                              }}/>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
              <Col md={2}></Col>
              <Col md={4}>
                  <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
              </Col>
          </Row>
        </Container>
      </div>
      <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>

      <Modal show={cvDialog} onHide={closeCvDialog} backdrop="static" size="xl">
        <ModalHeader closeButton  style={{ backgroundColor: "#ccd2da" }}>Create new CV template</ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={2}>
                Template Name:
              </Col>
              <Col md={6}>
                <FormControl type="text" onChange={e => setCvTemplateName(e.target.value)} value={cvTemplateName}/>
              </Col>
            </Row>
            <Row style={{marginTop: "10px"}}>
              <Col md={12}>
                <FormBuilderComp ref={formBuilderCompRef} data={cvTemplate} action={cvAction}/>
              </Col>
            </Row>
          </Container>            
        </ModalBody>
        <ModalFooter>
          <Button variant="info" onClick={closeCvDialog}>Close</Button>
          <Button variant="success" onClick={cvActionHandler}>
            {cvAction === "new" &&
              <>Save</>
            }
            {cvAction === "edit" &&
              <>Update</>
            }
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
