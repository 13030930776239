import FormProvider from "@components/hook-form/form-provider";
import RHFCheckbox from "@components/hook-form/rhf-checkbox";
import RHFDatePicker from "@components/hook-form/rhf-date-picker";
import RHFSelect from "@components/hook-form/rhf-select";
import RHFTextField from "@components/hook-form/rhf-text-field";
import Notification from "@components/Notification";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBoolean } from "@hooks/useBoolean";
import { getCities } from "@services/calendarService";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import { orderBy, map } from "lodash";
import { listAllPackageRequest } from "@services/packageService";
import SelectUser from "../components/SelectUser";
import { createCalendarRequest } from "@services/calendarService";
import { userDetailRequest } from "../../../../services/userService";

import { Form, Nav } from "react-bootstrap";

export const CITIES = ["Hồ Chí Minh", "Bà Rịa-Vũng Tàu", "Hà Nội"];

const defaultValues = {
  city: CITIES[0], // Hồ Chí Minh
  district: "",
  street: "",
  service: "",
  note: "",
  fullName: "",
  phoneNumber: "",
  email: "",
  aging: "",
  gender: "",
  calendarId: "",
  contactedBy: "",
  consultantBy: "",
  customerNumber: "",
  registerDate: "",
  healthStatus: "",
  packageItemId: "",
  reason: "",
  detailReason: "",
  customerSatisfied: false,
  customerSatisfiedDetail: "",
  customerSatisfiedRate: 0,
  isReBooked: false,
  contactFlow: "inbound",
  sourceFrom: "",
  level: "",
  specialPriceRequest: "",
  specialRequest: "",
  serviceStartDuration: "",
  personalPreference: "",
  quotationsNumner: 0,
  quotationsReason: "",
  potentialGroup: "",
  customerFeedback: "",
};

const bookingSchema = Yup.object().shape({
  city: Yup.mixed().required("Bắt buộc nhập thành phố"),
  district: Yup.string().required("Bắt buộc nhập quận/huyện"),
  street: Yup.string().required("Bắt buộc nhập số nhà/đường"),
  note: Yup.string(),
  fullName: Yup.string().required("Bắt buộc nhập họ tên"),
  phoneNumber: Yup.string().required("Bắt buộc nhập số điện thoại"),
});

const AddContact = (props) => {
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [cities, setCities] = useState([]);
  const [packages, setPackages] = useState([]);

  const isShowSelectUser = useBoolean(false);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(bookingSchema),
  });

  const {
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const values = watch();

  // const packageItems = packageItemsData.filter((item) => item.itemStatus === 'actived-ready');

  const citiesFiltered = orderBy(
    cities.filter((city) => CITIES.includes(city.cityName)),
    ["cityName"],
    ["asc"]
  );

  const districts = useMemo(() => {
    return (
      orderBy(
        cities.find((city) => city.cityName === values.city)
          ?.districtCategories,
        ["districtName"],
        ["asc"]
      ) || []
    );
  }, [cities, values.city]);

  const showToast = useBoolean(false);

  const showMessageToast = useCallback(
    (msg, variant) => {
      showToast.onTrue();
      setToastContent(msg);
      setToastVariant(variant);
    },
    [showToast]
  );

  useEffect(() => {
    getCities().then((cities) => {
      if (cities.isError) {
        showMessageToast("Failed to get cities", "danger");
        return;
      }
      setCities(cities?.items || []);
    });
    listAllPackageRequest().then((packages) => {
      if (packages.isError) {
        showMessageToast("Failed to get packages", "danger");
        return;
      }
      setPackages(packages.items);
    });
  }, []);

  const { handleSubmit } = methods;

  useEffect(() => {
    if (districts.length === 0) return;
    methods.setValue("district", districts[0]?.districtName || "");
  }, [districts, methods]);

  const submit = async (values) => {
    // const bookingData = {
    //   familyMemberId: values.familyMemberId,
    //   addressType: values.addressType,
    //   address: `${values.street}, ${values.district}, ${values.city}`,
    //   packageItemId: values.serviceType,
    //   note: values.note,
    //   datetime: values.bookingDate,
    //   length: +values.bookingHour,
    //   paymentStatus: values.paymentStatus,
    // };
    // const res = await createCalendarRequest(bookingData);
    // if (res.isError) {
    //   showMessageToast("Failed to create", "danger");
    //   return;
    // }
    // showMessageToast("Create successfully", "success");
  };

  const onSelectUser = useCallback(
    (user) => {
      setValue("consultantBy", user);
    },
    [setValue]
  );

  return (
    <div className="main main-docs">
      <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
        <Container>
          <label className="main-title-label">Contact</label>
          <h2 className="main-title">Add new contact</h2>

          <h5 className="main-subtitle" id="section-1">
            1. Thông tin cơ bản
          </h5>

          <Row className="align-items-center mt-4">
            <Col>
              <Form.Label>Full Name:</Form.Label>
              <RHFTextField name="fullName" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Phone Number:</Form.Label>
              <RHFTextField name="phoneNumber" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Email:</Form.Label>
              <RHFTextField name="email" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Aging:</Form.Label>
              <RHFTextField name="aging" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Gender:</Form.Label>
              <RHFTextField name="gender" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>City:</Form.Label>
              <RHFSelect name="city">
                {map(citiesFiltered, (city) => (
                  <option key={city.id} value={city.cityName}>
                    {city.cityName}
                  </option>
                ))}
              </RHFSelect>
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>District:</Form.Label>
              <RHFSelect name="district">
                {map(districts, (district) => (
                  <option key={district.id} value={district.districtName}>
                    {district.districtName}
                  </option>
                ))}
              </RHFSelect>
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Street:</Form.Label>
              <RHFTextField name="street" />
            </Col>
          </Row>

          <hr className="main-separator" />

          <h5 className="main-subtitle" id="section-2">
            2. Thông tin nghề nghiệp
          </h5>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Service:</Form.Label>
              <RHFTextField name="service" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Contacted By:</Form.Label>
              <RHFTextField name="contactedBy" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Consultant By:</Form.Label>
              <RHFTextField name="consultantBy" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Customer Number:</Form.Label>
              <RHFTextField name="customerNumber" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Register Date:</Form.Label>
              <RHFTextField name="registerDate" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Health Status:</Form.Label>
              <RHFTextField name="healthStatus" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-start mt-4">
            <Col md={6}>
              <Form.Label>Reason:</Form.Label>
              <RHFTextField name="reason" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Detail Reason:</Form.Label>
              <RHFTextField name="detailReason" as="textarea" />
            </Col>
          </Row>

          <hr className="main-separator" />

          <h5 className="main-subtitle" id="section-3">
            3. Thông tin khảo sát
          </h5>

          <Row className="align-items-start mt-4">
            <Col md={6}>
              <Form.Label>Customer Satisfied:</Form.Label>
              <RHFTextField name="customerSatisfied" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Customer Satisfied Detail:</Form.Label>
              <RHFTextField name="customerSatisfiedDetail" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={4}>
              <Form.Label>Level:</Form.Label>
              <RHFTextField name="level" />
            </Col>
            <Col md={4} className="mt-4 mt-md-0">
              <Form.Label>Contact Flow:</Form.Label>
              <RHFTextField name="contactFlow" />
            </Col>
            <Col md={4} className="mt-4 mt-md-0">
              <Form.Label>Source From:</Form.Label>
              <RHFTextField name="sourceFrom" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Special Price Request:</Form.Label>
              <RHFTextField name="specialPriceRequest" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Special Request:</Form.Label>
              <RHFTextField name="specialRequest" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Personal Preference:</Form.Label>
              <RHFTextField name="personalPreference" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={6}>
              <Form.Label>Service Start Duration:</Form.Label>
              <RHFTextField name="serviceStartDuration" />
            </Col>
            <Col md={6} className="mt-4 mt-md-0">
              <Form.Label>Quotations Numner:</Form.Label>
              <RHFTextField name="quotationsNumner" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Quotations Reason:</Form.Label>
              <RHFTextField name="quotationsReason" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Potential Group:</Form.Label>
              <RHFTextField name="potentialGroup" />
            </Col>
          </Row>

          <hr className="main-separator" />

          <h5 className="main-subtitle" id="section-4">
            4. Thông tin khác
          </h5>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Customer Feedback:</Form.Label>
              <RHFTextField name="customerFeedback" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Form.Label>Note:</Form.Label>
              <RHFTextField name="note" as="textarea" />
            </Col>
          </Row>

          <Row className="align-items-center mt-4">
            <Col md={12}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </FormProvider>
      <Notification
        showToast={showToast.value}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={showToast.onFalse}
      />
      <SelectUser
        open={isShowSelectUser.value}
        onClose={isShowSelectUser.onFalse}
        onSelect={onSelectUser}
      />

      <Nav id="navDocs" className="nav-docs">
        <label>On This Page</label>
        <Nav.Link href="#section-1">1. Thông tin cơ bản</Nav.Link>
        <Nav.Link href="#section-2">2. Thông tin nghề nghiệp</Nav.Link>
        <Nav.Link href="#section-3">3. Thông tin khảo sát</Nav.Link>
        <Nav.Link href="#section-4">4. Thông tin khác</Nav.Link>
      </Nav>
    </div>
  );
};

export default memo(AddContact);
