import FormProvider from "@components/hook-form/form-provider";
import PaginationComp from "@components/PaginationComp";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { listUserRequest } from "@services/userService";
import RHFTextField from "@components/hook-form/rhf-text-field";

const defaultValues = {
    phoneNumber: "",
}

const searchUserSchema = Yup.object().shape({
  phoneNumber: Yup.mixed().required("Bắt buộc nhập số điệnt thoại"),
});

const SelectUser = ({open, onClose, onSelect}) => {
    const [users, setUsers] = useState([]);
    const [userTotal, setUserTotal] = useState(0);
    const [userPageSize, setUserPageSize] = useState(0);
    const [userPageIndex, setUserPageIndex] = useState(0);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(searchUserSchema),
  });

  

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    const result = await listUserRequest("", data.phoneNumber, "", "", userPageIndex, 10);
    if (result.isError) {
      return;
    }
    setUsers(result.items);
    setUserTotal(result.total);
    setUserPageSize(10);
  };

  useEffect(() => {
    onSubmit({
        phoneNumber: ""
    });
  }, [userPageIndex]);

  const handleUserPaginationCallback = (size, index) => {
    setUserPageSize(size);
    setUserPageIndex(index);
  };

  return (
    <Modal show={open} onHide={onClose} size="xl" backdrop="static">
      <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
        Select a User
      </ModalHeader>
      <ModalBody>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Row>
              <Col md={3}>User's phoneNumber:</Col>
              <Col md={4}>
                <RHFTextField name="phoneNumber" />
              </Col>
              <Col md={2}>
                <Button variant="primary" type="submit" size="sm">
                  Search
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col md={12}>
                <table className="table table-bordered table-hover table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>Full name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th style={{ textAlign: "center" }}>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users && (
                      <>
                        {users.map((user) => {
                          return (
                            <>
                              <tr>
                                <td>{user.fullName}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.email}</td>
                                <td style={{ textAlign: "center" }}>
                                  <Button
                                    variant="success"
                                    size="sm"
                                    onClick={(e) => {
                                        onSelect(user);
                                        onClose();
                                    }}
                                  >
                                    Select
                                  </Button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <PaginationComp
                  total={userTotal}
                  pageSize={userPageSize}
                  callback={handleUserPaginationCallback}
                />
              </Col>
            </Row>
          </Container>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default SelectUser;
