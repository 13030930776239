import { yupResolver } from "@hookform/resolvers/yup";
import React, { memo, useState } from "react";
import {
    Button,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import FormProvider from "@components/hook-form/form-provider";
import RHFTextField from "@components/hook-form/rhf-text-field";
import Notification from "@components/Notification";
import { useBoolean } from "@hooks/useBoolean";
import { createNewUser, updateUserRequest } from "@services/userService";

const defaultValues = {
    fullName: '',
    phoneNumber: '',
    password: ''
}

const addUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    password: Yup.string().required('Password is required'),
});

const AddUserModal = ({open, onClose}) => {
    const [toastContent, setToastContent] = useState("");
    const [toastVariant, setToastVariant] = useState("success");

    const showToast = useBoolean(false)
    const methods = useForm({
        defaultValues,
        resolver: yupResolver(addUserSchema)
    })

    const showMessageToast = (msg, variant) => {
        showToast.onTrue()
        setToastContent(msg)
        setToastVariant(variant)
    }

    const {handleSubmit} = methods

    const submit = async (values) => {
        console.log(values)
        const createdUser = await createNewUser({
            user: {
                phoneNumber: values.phoneNumber,
                password: values.password
            }
        })
        if (createdUser.isError) {
            return showMessageToast('Create failed, account can be existed', 'danger')
        }
        await updateUserRequest({
            fullName: values.fullName
        }, createdUser.id)
        showMessageToast('Create successfully', 'success')

    }
    return (
        <Modal
            show={open}
            onHide={onClose}
            size="lg"
            backdrop="static"
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
                <ModalHeader closeButton style={{backgroundColor: "#ccd2da"}}>
                    Add new user
                </ModalHeader>

                <ModalBody>
                    <Container>
                        <Row className="align-items-center">
                            <Col md={3}>Name:</Col>
                            <Col md={9}>
                                <RHFTextField name="fullName"/>
                            </Col>
                        </Row>

                        <Row className="align-items-center mt-4">
                            <Col md={3}>Phone number:</Col>
                            <Col md={9}>
                                <RHFTextField name="phoneNumber"/>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-4">
                            <Col md={3}>Password:</Col>
                            <Col md={9}>
                                <RHFTextField name="password"/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>

                <ModalFooter>
                    <Button variant="primary" type="submit">
                        Add
                    </Button>

                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </FormProvider>
            <Notification showToast={showToast.value} toastContent={toastContent} toastVariant={toastVariant}
                          closeNotification={showToast.onFalse}/>
        </Modal>
    );
};

export default memo(AddUserModal);
