import React, { useEffect, useState } from "react";
import Header from "@layouts/Header";
import { Button, Col, Container, FormControl, FormSelect, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "react-bootstrap";
import DatePicker from "react-date-picker";
import ReactQuill from "react-quill";
import { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { ClockLoader } from "react-spinners";
import { createSystemMessageRequest, deleteSystemMessageRequest, getSystemMessageRequest, listSystemMessageRequest, updateSystemMessageRequest } from "@services/notificationService";
import Notification from "@components/Notification";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import PaginationComp from "@components/PaginationComp";
// import { DateRange  } from "react-date-range";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

export default function SystemMessage(){
    let time1 = new Date();
    let time2 = new Date();
    time2.setDate(time2.getDate() - 7);
    const [messages, setMessages] = useState([]);
    const [fromDate, setFromDate] = useState(time2);
    const [toDate, setToDate] = useState(time1);
    const [searchTitle, setSearchTitle] = useState("");
    const [searchType, setSearchType] = useState("all");
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");
    const [toastVariant, setToastVariant] = useState("success");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalItem, setTotalItem] = useState(0);

    const [messageDialog, setMessageDialog] = useState(false);
    const [activeDate, setActiveDate] = useState([
        new DateObject(),
        new DateObject().add(4, "days")
    ])
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [notificationType, setNotificationType] = useState("system");
    const [dialogType, setDialogType] = useState("create");
    const [messageId, setMessageId] = useState("");
    const [shortDesc, setShortDesc] = useState("");

    const [deleteDialog, setDeleteDialog] = useState(false);

    const closeDeleteDialog = async() => {
        setDeleteDialog(false);
        setMessageId("");
        await searchMessage();
    }

    const closeMessageDialog = async() => {
        setMessageDialog(false);
        setTitle("");
        setContent("");
        setNotificationType("system");
        setActiveDate([
            new DateObject(),
            new DateObject().add(4, "days")
        ])
        setMessageId("");
        setShortDesc("");
        if(pageIndex !== 0){
            setPageIndex(0)
        }else{
            await searchMessage();
        }
    }
    const displayNotification = (content, variant) => {
        setToastVariant(variant);
        setToastContent(content);
        setShowToast(true);
      };
      const closeNotification = () => {
        setShowToast(false);
        setLoading(false);
      };

    const saveItem = async() => {
        // console.log(activeDate);
        let startDate = null;
        let endDate = null;
        for(let i = 0; i < activeDate.length; i++){
            if(i === 0){
                startDate = activeDate[i].format();
            }else{
                endDate = activeDate[i].format();
            }
        }
        if(endDate === null){
            endDate = startDate;
        }
        const data = await createSystemMessageRequest(title, content, startDate, endDate, shortDesc, notificationType);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        displayNotification("Create system message successfully", "success");
        closeMessageDialog()
    }

    const searchMessage = async() => {
        setLoading(true)
        const data = await listSystemMessageRequest(searchTitle, fromDate, toDate, searchType, pageIndex, pageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        let items = [];
        setTotalItem(data.total);
        for(let i = 0; i < data.items.length; i++){
            let content = data.items[i].content.replace(/<[^>]*>/g, '');
            content = content.substr(0, 60) + "...";
            items.push({
                title: data.items[i].title,
                content: content,
                notificationType: data.items[i].notificationType,
                activeDate: (DateTime.fromISO(data.items[i].activedFrom)).toISODate(DateTime.DATETIME_SHORT) 
                + " - " + (DateTime.fromISO(data.items[i].activedTo)).toISODate(DateTime.DATETIME_SHORT),
                id: data.items[i].id,
                shortDescription: data.items[i].shortDescription
            });
        }
        setMessages(items)
        setLoading(false);
    }
    const getMessage = async(messageId) => {
        setLoading(true);
        const data = await getSystemMessageRequest(messageId);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setTitle(data.title);
        setContent(data.content);
        setNotificationType(data.notificationType);
        setActiveDate([
            new DateObject(data.activedFrom),
            new DateObject(data.activedTo)
        ])
        setShortDesc(data.shortDescription);
        setMessageDialog(true)
        setLoading(false);
    }
    const updateMessage = async() => {
        setLoading(true);
        let startDate = null;
        let endDate = null;
        for(let i = 0; i < activeDate.length; i++){
            console.log(activeDate[i]);
            if(i === 0){
                
                startDate = activeDate[i].format();
            }else{
                endDate = activeDate[i].format();
            }
        }
        if(endDate === null){
            endDate = startDate;
        }
        const data = await updateSystemMessageRequest(messageId, title, content, startDate, endDate, notificationType, shortDesc);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        displayNotification("Message is updated", "success");
        closeMessageDialog();
    }
    const deleteMessage = async() =>{
        setLoading(true);
        const data = await deleteSystemMessageRequest(messageId);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        displayNotification("Message is deleted", "success");
        closeDeleteDialog();
    }
    const handlePaginationCallback = async (pageSize, pageIndex) => {
        setPageIndex(pageIndex);
    };
    useEffect(() => {
        searchMessage()
    }, [pageIndex]);

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row>
                        <Col md={2}>
                            Message title: 
                        </Col>
                        <Col md={4}>
                            <FormControl type="text" onChange={e => setSearchTitle(e.target.value)}/>
                        </Col>
                        <Col md={2}>
                            <Button variant="primary" onClick={e => {
                                if(pageIndex !== 0){
                                    setPageIndex(0)
                                }else{
                                    searchMessage()
                                }
                            }}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            From:
                        </Col>
                        <Col md={2}>
                            <DatePicker value={time2} onChange={setFromDate}/>
                        </Col>
                        <Col md={1}>
                            To:
                        </Col>
                        <Col md={2}>
                            <DatePicker value={time1} onChange={setToDate}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={2}>
                            Message Type:
                        </Col>
                        <Col md={3}>
                            <FormSelect onChange={e => setSearchType(e.target.value)}>
                                <option value="all">All</option>
                                <option value="system">System</option>
                                <option value="marketing">Marketing</option>
                            </FormSelect>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={12} style={{textAlign: "right"}}>
                            <Button variant="success" onClick={e => {
                                setDialogType("create");
                                setMessageDialog(true)
                            }}>Add new</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Short description</th>
                                        <th>Content</th>
                                        <th>Type</th>
                                        <th>Actived date</th>
                                        <th style={{textAlign: "center"}}>View</th>
                                        <th style={{textAlign: "center"}}>Edit</th>
                                        <th style={{textAlign: "center"}}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {messages && 
                                        <>
                                            {messages.map((message) => {
                                                return (
                                                    <tr>
                                                        <td>{message.title}</td>
                                                        <td>{message.shortDescription}</td>
                                                        <td>
                                                            {message.content}
                                                        </td>
                                                        <td>
                                                            {message.notificationType}
                                                        </td>
                                                        <td>
                                                            {message.activeDate}
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <FontAwesomeIcon icon={faEye} onClick={e => {
                                                                setDialogType("view");
                                                                getMessage(message.id);
                                                                
                                                            }}/>
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <FontAwesomeIcon icon={faEdit} onClick={ e=> {
                                                                setDialogType("edit");
                                                                setMessageId(message.id);
                                                                getMessage(message.id);
                                                            }}/>
                                                        </td>
                                                        <td style={{textAlign: "center"}}>
                                                            <FontAwesomeIcon icon={faTrash} onClick={e => {
                                                                setDeleteDialog(true)
                                                                setMessageId(message.id);
                                                            }}/>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </>
                                    }
                                    {messages.length === 0 &&
                                        <tr>
                                            <th colSpan={4} style={{textAlign: "center"}}>No data</th>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={4}>
                        <PaginationComp
                            total={totalItem}
                            pageSize={pageSize}
                            callback={handlePaginationCallback}
                        />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={messageDialog} onHide={closeMessageDialog} backdrop="static" size="lg">
                <ModalHeader closeButton style={{ backgroundColor: "lightblue" }}>
                    {dialogType === "create" &&
                        <>Create new system message</>
                    }
                    {dialogType === "view" &&
                        <>System message detail</>
                    }
                    {dialogType === "edit" &&
                        <>Edit system message</>
                    }
                </ModalHeader>
                <ModalBody>
                    <Container fluid="md">
                        <Row>
                            <Col md={3}>
                                Message title:
                            </Col>
                            <Col md={5}>
                                <FormControl value={title} type="text" onChange={e => setTitle(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}>
                                Message Type:
                            </Col>
                            <Col md={5}>
                                <FormSelect defaultValue={notificationType} onChange={e => setNotificationType(e.target.value)}>
                                    <option value="system">System</option>
                                    <option value="marketing">Marketing</option>
                                </FormSelect>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}>
                                Actived date:
                            </Col>
                            <Col md={9}>
                                {/* <DateRange 
                                    ranges={activeDate}
                                    onChange={item => setActiveDate([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    editableDateInputs={true}
                                /> */}
                                <Calendar 
                                    value={activeDate}
                                    onChange={setActiveDate}
                                    range
                                    rangeHover
                                    plugins={[
                                        <DatePanel />
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}>
                                Short Description:
                            </Col>
                            <Col md={9}>
                                <FormControl type="text" as="textarea" value={shortDesc} onChange={e => setShortDesc(e.target.value)}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col md={3}>
                                Message Content:
                            </Col>
                            <Col md={9}>
                                <ReactQuill
                                    theme="snow"
                                    className="border mt-3 rounded"
                                    onChange={e => setContent(e)}
                                    value={content}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {dialogType === "create" &&
                        <Button variant="success" onClick={saveItem}>Save</Button>
                    }
                    {dialogType === "edit" &&
                        <Button variant="success" onClick={updateMessage}>Update</Button>
                    }
                    <Button variant="secondary" onClick={closeMessageDialog}>
                        {dialogType === "view" &&
                            <>Close</>
                        }
                        {dialogType !== "view" &&
                            <>Cancel</>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal show={deleteDialog} onHide={closeDeleteDialog} backdrop="static">
                <ModalHeader closeButton>Do you confirm deleting this message?</ModalHeader>
                <ModalFooter>
                    <Button variant="danger" onClick={deleteMessage}>Confirm</Button>
                    <Button variant="secondary" onClick={closeDeleteDialog}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <div
                style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                zIndex: 9999,
                }}
            >
                <ClockLoader loading={loading} color="red" />
            </div>
            <Notification
                showToast={showToast}
                toastContent={toastContent}
                toastVariant={toastVariant}
                closeNotification={closeNotification}
            />
        </React.Fragment>
    )
}