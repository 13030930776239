import React from "react";
import {FormControl} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";

// ----------------------------------------------------------------------

export default function RHFTextField({name, helperText, type, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <>
                    <FormControl
                        {...field}
                        type={type}
                        value={type === "number" && field.value === 0 ? "" : field.value}
                        onChange={(event) => {
                            if (type === "number") {
                                field.onChange(Number(event.target.value));
                            } else {
                                field.onChange(event.target.value);
                            }
                        }}
                        {...other}
                    />
                    {error && <div style={{color: 'red'}}>{error.message}</div>}
                </>
            )}
        />
    );
}
