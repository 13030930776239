import React from "react";
import {FormControl} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import DatePicker from "react-date-picker";

// ----------------------------------------------------------------------

export default function RHFDatePicker({name, helperText, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <>
                    <DatePicker
                        {...field}
                        onChange={field.onChange}
                        {...other}
                    />
                    {error && <div style={{color: 'red'}}>{error.message}</div>}
                </>
            )}
        />
    );
}
