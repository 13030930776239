import React from "react";
import {FormControl, FormSelect} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";

// ----------------------------------------------------------------------

export default function RHFSelect({name, helperText, children, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <>
                    <FormSelect
                        {...field}
                        onChange={(event) => {
                            field.onChange(event.target.value);
                        }}
                        {...other}
                    >{children}</FormSelect>
                    {error && <div style={{color: 'red'}}>{error.message}</div>}
                </>
            )}
        />
    );
}
