import React from "react";
import {FormCheck, FormControl} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";

// ----------------------------------------------------------------------

export default function RHFCheckbox({name, helperText, type, label, ...other}) {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({field, fieldState: {error}}) => (
                <>
                    <FormCheck
                        {...field}
                        type={type}
                        label={label}
                        onChange={e => {
                            field.onChange(e.target.value);
                        }}
                        checked={field.value === other.value}
                        {...other}
                    />
                    {error && <div style={{color: 'red'}}>{error.message}</div>}
                </>
            )}
        />
    );
}
