import Notification from "@components/Notification";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "@layouts/Header";
import { getCitiesRequest } from "@services/otherService";
import { addFamilyMemberRequest, getFamilyMemberRequest, updateFamilyMemberRequest, userDetailRequest } from "@services/userService";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormSelect,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useSearchParams } from "react-router-dom";

const UserFamilyMember = () => {
  const [queryParams] = useSearchParams();

  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");
  const [user, setUser] = useState({})
  const [members, setMembers] = useState([])
  const [cities, setCities] = useState([]);
  const [districtObject, setDistrictObject] = useState({});
  const [districts, setDistricts] = useState([]);

  const [familyMemberDialog, setFamilyMemberDialog] = useState(false);
  const [surname, setSurname] = useState("");
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("");
  const [relation, setRelation] = useState("father")
  const [city, setCity] = useState("")
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [sex, setSex] = useState(0)
  const [dob, setDob] = useState(new Date());
  const [action, setAction] = useState("new");
  const [selectedMemberId, setSelectedMemberId] = useState("")

  const closeFamilyMemberDialog = async() => {
    setFamilyMemberDialog(false);
    await getUser()
  }

  const userId = queryParams.get("id");

  const getUser = async() => {
    const data = await userDetailRequest(userId);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setUser(data.user)
    if(data.familyMembers){
      setMembers(data.familyMembers)
    }
  }

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };
  const closeNotification = () => {
    setShowToast(false);
  };

  const listCity = async() => {
    const data = await getCitiesRequest();
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    let cityArray = [];
    let dObject = {}
    for(let i = 0; i < data.items.length; i++){
      cityArray.push({
        id: data.items[i].id,
        cityName: data.items[i].cityName
      });
      dObject[data.items[i].id] = data.items[i].districtCategories
    }
    setDistrictObject(dObject);
    setCities(cityArray);
  }

  const selectCity = (e) => {
    const value = e.target.value;
    if(value !== ""){
      setDistricts(districtObject[value]);
    }else{
      setDistricts([])
    }
    
  }

  const createMember = async() => {
    const data = await addFamilyMemberRequest(userId, relation, surname, name, phoneNumber, dob, city, district, address, sex);
    if(data.isError){
      displayNotification(data.msg, "danger")
      return;
    }
    closeFamilyMemberDialog()
  }

  const getFamilyMember = async(id) => {
    const data = await getFamilyMemberRequest(id);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setSelectedMemberId(id);
    setSurname(data.surname);
    setName(data.name);
    setPhoneNumber(data.phoneNumber)
    setRelation(data.relation)
    setCity(data.cityId);
    setDistrict(data.districtId)
    setAddress(data.address)
    setSex(data.sex)
    setDob(data.dob);
  }

  const updateMember = async() => {
    const data = await updateFamilyMemberRequest(selectedMemberId, relation, surname, name, phoneNumber, dob,
      city, district, address, sex);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    closeFamilyMemberDialog();
  }

  useEffect(() => {
    if(userId !== ""){
      getUser()
    }
  }, [userId]);
  useEffect(() => {
    listCity()
  }, []);
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={12}></Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  User management
                </li>
                <li className="breadcrumb-item">
                  User detail: {user.id}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Total family members
                </li>
              </ol>
            </nav>
          </Row>

          <Row>
            <Col style={{textAlign: "right"}}>
              <button type="button" class="btn btn-primary" onClick={e => {
                setAction("new");
                setFamilyMemberDialog(true);
              }}>
                Add New Member
              </button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md={12}>
              <Table bordered striped>
                <thead className="table-primary">
                  <tr style={{ fontWeight: "bold" }}>
                    <th>No</th>
                    <th>Family Number</th>
                    <th>Created Time</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Lasted Cared</th>
                    <th style={{ textAlign: "center" }}>View/Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {members.length <= 0 &&
                    <tr>
                      <td colSpan={7} style={{textAlign: "center"}}>
                        no data
                      </td>
                    </tr>
                  }
                  {members &&
                    <>
                      {members.map((member, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {member.phoneNumber ? member.phoneNumber : "N/A"}
                            </td>
                            <td>
                              {member.createdAt}
                            </td>
                            <td>
                              {member.isDeleted? "Inactived" : "Actived"}
                            </td>
                            <td>
                              {member.surname + " " + member.name}
                            </td>
                            <td>
                              N/A
                            </td>
                            <td style={{textAlign: "center"}}>
                              <FontAwesomeIcon icon={faEdit} onClick={e => {
                                setAction("edit");
                                getFamilyMember(member.id)
                                setFamilyMemberDialog(true)
                              }}/>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>

          {/* <Row>
            <Col md={2}></Col>
            <Col md={4}>
              <PaginationComp
                total={totalItem}
                pageSize={pageSize}
                callback={handlePaginationCallback}
              />
            </Col>
          </Row> */}
        </Container>
      </div>
      <Modal show={familyMemberDialog} onHide={closeFamilyMemberDialog} backdrop="static" size="lg">
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Add family member
        </ModalHeader>
        <ModalBody>
          <Container fluid>
            <Row>
              <Col md={2}>
                First name:
              </Col>
              <Col md={3}>
                <FormControl type="text" onChange={e => setSurname(e.target.value)} value={surname}/>
              </Col>
              <Col md={3}>
                Last name:
              </Col>
              <Col md={2}>
                <FormControl type="text" onChange={e => setName(e.target.value)} value={name}/>
              </Col>
            </Row>
            <Row style={{marginTop: "15px"}}>
              <Col md={2}>
                Phone number:
              </Col>
              <Col md={4}>
                <FormControl type="text" onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber}/>
              </Col>
            </Row>
            <Row style={{marginTop: "15px"}}>
              <Col md={2}>
                Relation:
              </Col>
              <Col md={3}>
                <FormSelect onChange={e => setRelation(e.target.value)} value={relation}>
                  <option value="father">Father</option>
                  <option value="mother">Mother</option>
                  <option value="grandfather">Grand Father</option>
                  <option value="grandmother">Grand Mother</option>
                </FormSelect>
              </Col>
              <Col md={1}>
                Sex:
              </Col>
              <Col md={2}>
                <FormSelect onChange={e => setSex(e.target.value)} value={sex}>
                  <option value={0}>Male</option>
                  <option value={1}>Female</option>
                </FormSelect>
              </Col>
            </Row>
            <Row style={{marginTop: "15px"}}>
              <Col md={2}>
                Date of birth:
              </Col>
              <Col md={3}>
                <DatePicker onChange={setDob} maxDate={new Date()} value={dob}/>
              </Col>
            </Row>
            <Row style={{marginTop: "15px"}}>
              <Col md={2}>
                City:
              </Col>
              <Col md={3}>
                <FormSelect onChange={e => {
                  // selectCity(e)
                  setCity(e.target.value)
                }} value={city}>
                  <option value="">Choose a city...</option>
                  {cities.map((c) => {
                    return (
                      <option value={c.id}>{c.cityName}</option>
                    )
                  })}
                </FormSelect>
              </Col>
              <Col md={2}>
                District:
              </Col>
              <Col md={3}>
                <FormSelect onChange={e => setDistrict(e.target.value)} value={district}>
                  <option value="">Choose a district...</option>
                  {city &&
                    <>
                       {districtObject[city].map((d) => {
                          return (
                            <option value={d.id}>{d.districtName}</option>
                          )
                        })}
                    </>
                  }
                </FormSelect>
              </Col>
            </Row>
            <Row style={{marginTop: "15px"}}>
              <Col md={2}>
                Address:
              </Col>
              <Col md={10}>
                <FormControl type="text" onChange={e => setAddress(e.target.value)} value={address}/>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={e =>{
            if(action == "new"){
              createMember()
            }else{
              updateMember()
            }
          }}>
            {action === "new" &&
              <>Save</>
            }
            {action === "edit" &&
              <>Update</>
            }
          </Button>
          <Button variant="secondary" onClick={closeFamilyMemberDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />
    </React.Fragment>
  );
};

export default UserFamilyMember;
