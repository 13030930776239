import React, { useState, useEffect } from "react";
import Header from "@layouts/Header";
import {
  Button,
  Col,
  Container,
  FormCheck,
  Row,
  Table,
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "react-bootstrap";
import userAvatar from "@assets/img/img1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams, useNavigate } from "react-router-dom";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  userDetailRequest,
  changeUserRoleRequest,
  listSystemRoleRequest,
  changeUserRoleStatusRequest,
} from "@services/userService";
import { DateTime } from "luxon";
import Notification from "@components/Notification";

const UserDetail = () => {
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  // const [roles, setRoles] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const [showChangeRoleDialog, setShowChangeRoleDialog] = useState(false);
  const [editRoles, setEditRoles] = useState([]);
  const [systemRoles, setSystemRoles] = useState([])

  const userId = queryParams.get("id");

  const [roleStatusDialog, setRoleStatusDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleStatus, setSelectedRoleStatus] = useState(false);
  const [selectedUserRoleId, setSelectedUserRoleId] = useState("")

  const closeRoleStatusDialog = async() => {
    setRoleStatusDialog(false);
    await getUserDetail();
  }
  

  const getUserDetail = async () => {
    const data = await userDetailRequest(userId);
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }

    let roles = [];
    // let rs = []
    if (data.user.userRoles) {
      for (let i = 0; i < data.user.userRoles.length; i++) {
        // rs.push(data.user.UserRoles[i].Role.roleName);
        if (data.user.userRoles[i].isActive) {
          roles.push(`${data.user.userRoles[i].role.roleName}-active`);
        } else {
          roles.push(`${data.user.userRoles[i].role.roleName}-inactive`);
        }
      }
    }

    let items = {};
    if (data) {
      items.id = data.user.id;
      items.phone = data.user.phoneNumber;
      items.createdAt = data.user.createdAt;
      items.totalUserBooking = data.totalUserBooking;
      items.totalCareGiverBooking = data.totalCareGiverBooking;
      items.totalFamilyMember = data.familyMembers.length;
      items.roles = data.user.userRoles
    }

    setUserRoles(roles);
    // setRoles(rs);
    setUserDetail(items);
  };

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };
  const closeNotification = () => {
    setShowToast(false);
  };

  useEffect(() => {
    getUserDetail();
    loadRole();
  }, []);

  //Change role
  const onEditRoleClick = () => {
    let roles = [];
    let urs = userDetail.roles;
    for(let i=0; i < urs.length; i++){
      roles.push(urs[i].role.id);
    }
    setEditRoles(roles);
    setShowChangeRoleDialog(true);
  };

  const onCloseChangeRoleDialog = () => {
    setShowChangeRoleDialog(false);
    getUserDetail()
  };
  //when user add/remove a role
  const onRoleChange = (e) => {
    const {value, checked} = e.target;
    
    
    if(editRoles.includes(value)) {
      let newRoles = editRoles.filter((role) => role !== value);
      setEditRoles(newRoles);
    } else {
      
      setEditRoles([...editRoles, value]);
    }
    
  }
  //load system role
  const loadRole = async() => {
    const data = await listSystemRoleRequest();
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    let items = [];
    for(let i = 0; i < data.items.length; i++){
      items[data.items[i].roleName] = data.items[i].id;
    }
    setSystemRoles(items);
  }

  const onUpdateRoleClick = async() => {
    let data = null;
    data = await changeUserRoleRequest(
      userId,
      editRoles,
    );

    if (data.isError) {
      displayNotification(`Can not update role`, "danger");
      return;
    }

    displayNotification(`Update role successfully`, "success");
    onCloseChangeRoleDialog();
  };

  const changeRoleStatus = async() => {
    const data = await changeUserRoleStatusRequest(selectedUserRoleId, !selectedRoleStatus);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    closeRoleStatusDialog()
  }

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        {userDetail && (
          <>
            <Container fluid>
              <Row>
                <Col md={12}></Col>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="" onClick={() => navigate("/user/normal-user")}>
                        User management
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User detail: {userDetail.id}
                    </li>
                  </ol>
                </nav>
              </Row>

              <Row className="mt-3">
                <Col lg={5} className="d-flex align-content-center">
                  <div>
                    <Image
                      src={userAvatar}
                      alt="avatar"
                      width={120}
                      roundedCircle
                    />

                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ position: "absolute" }}
                      size="lg"
                      cursor={"pointer"}
                      className="ms-3"
                    />
                  </div>

                  <div className="ms-5 " style={{ height: "120px" }}>
                    <p>
                      <b className="fs-4">UserId: {userDetail.id}</b>
                    </p>
                    <p>Phone: {userDetail.phone}</p>
                    <p>
                      Created At:{" "}
                      {DateTime.fromISO(userDetail.createdAt).toISODate(
                        DateTime.DATETIME_SHORT
                      )}
                    </p>
                  </div>
                </Col>

                <Col lg={5}>
                  <p className="ms-3">
                    <b className="fs-4">Roles</b>

                    <FontAwesomeIcon
                      icon={faEdit}
                      size="lg"
                      cursor={"pointer"}
                      style={{ position: "absolute" }}
                      className="ms-3"
                      onClick={onEditRoleClick}
                    />
                  </p>

                  {userRoles.includes("user-active") && (
                    <span className="badge bg-primary ms-3">User</span>
                  )}
                  {userRoles.includes("user-inactive") && (
                    <span className="badge bg-primary ms-3">User</span>
                  )}
                  {userRoles.includes("admin-active") && (
                    <span className="badge bg-primary ms-3">Admin</span>
                  )}
                  {userRoles.includes("admin-inactive") && (
                    <span className="badge bg-primary ms-3">Admin</span>
                  )}
                  {userRoles.includes("caregiver-active") && (
                    <span className="badge bg-primary ms-3">Caregiver</span>
                  )}
                  {userRoles.includes("caregiver-inactive") && (
                    <span className="badge bg-primary ms-3">Caregiver</span>
                  )}
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg={12} xl={6}>
                  <div className="card">
                    <div className="card-header">
                      <b>User Role Management</b>
                    </div>
                    <div className="card-body">
                      <Table bordered striped>
                        <thead className="table-primary">
                          <tr style={{ fontWeight: "bold" }}>
                            <th>Content</th>
                            <th>Status</th>
                            <th style={{ textAlign: "center" }}>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Status</td>
                            <td>
                              {userRoles.includes("user-active")
                                ? "active"
                                : "inactive"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                                onClick={e => {
                                  if(userRoles.includes("user-active") || userRoles.includes("user-inactive")){
                                    setSelectedRole("user");
                                    if(userRoles.includes("user-active")){
                                      setSelectedRoleStatus(true);
                                    }else{
                                      setSelectedRoleStatus(false)
                                    }
                                    setRoleStatusDialog(true)
                                    console.log(userDetail.roles);
                                    for(let i = 0; i < userDetail.roles.length; i++){
                                      let role = userDetail.roles[i];
                                      if(role.role.roleName === "user"){
                                        setSelectedUserRoleId(role.id);
                                        break;
                                      }
                                    }
                                  }
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Total of Booking</td>
                            <td>{userDetail.totalUserBooking}</td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Total of Family Member</td>
                            <td>{userDetail.totalFamilyMember}</td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                                onClick={() => navigate(`/user/detail/familymember?id=${userDetail.id}`)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>

                <Col lg={12} xl={6}>
                  <div className="card">
                    <div className="card-header">
                      <b>Caregiver Role Management</b>
                    </div>
                    <div className="card-body">
                      <Table bordered striped>
                        <thead className="table-primary">
                          <tr style={{ fontWeight: "bold" }}>
                            <th>Content</th>
                            <th>Status</th>
                            <th style={{ textAlign: "center" }}>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Status</td>
                            <td>
                              {userRoles.includes("caregiver-active")
                                ? "active"
                                : "inactive"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                                onClick={e => {
                                  if(userRoles.includes("caregiver-active") || userRoles.includes("caregiver-inactive")){
                                    setSelectedRole("caregiver");
                                    if(userRoles.includes("caregiver-active")){
                                      setSelectedRoleStatus(true);
                                    }else{
                                      setSelectedRoleStatus(false)
                                    }
                                    setRoleStatusDialog(true)
                                    for(let i = 0; i < userDetail.roles.length; i++){
                                      let role = userDetail.roles[i];
                                      if(role.role.roleName === "caregiver"){
                                        setSelectedUserRoleId(role.id);
                                        break;
                                      }
                                    }
                                  }
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Services Applicated</td>
                            <td>01</td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Total of Booking</td>
                            <td>0</td>
                            <td style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                cursor={"pointer"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>

      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />

      {/* Changing role modal */}
      <Modal
        show={showChangeRoleDialog}
        onHide={onCloseChangeRoleDialog}
        size="md"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Edit roles
        </ModalHeader>

        <ModalBody>
          <Container>
            <Row className="align-items-center">
              <Col>
                <FormCheck disabled onChange={onRoleChange} type="checkbox" value={systemRoles["user"]} label="User" defaultChecked={userRoles.includes("user-active") ? true : userRoles.includes("user-inactive") ? true : false}  />
                <FormCheck onChange={onRoleChange} type="checkbox" value={systemRoles["caregiver"]} label="Caregiver" defaultChecked={userRoles.includes("caregiver-active") ? true : userRoles.includes("caregiver-inactive") ? true : false} />
                <FormCheck onChange={onRoleChange} type="checkbox" value={systemRoles["admin"]} label="Admin" defaultChecked={userRoles.includes("admin-active") ? true : userRoles.includes("admin-inactive") ? true : false} />
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={onUpdateRoleClick}>
            Update
          </Button>

          <Button variant="secondary" onClick={onCloseChangeRoleDialog}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal show={roleStatusDialog} onHide={closeRoleStatusDialog}>
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Change user's role status
        </ModalHeader>
        <ModalBody>
          Do you want to change <b>{selectedRole}</b> to <b style={{color: "red"}}>{selectedRoleStatus ? "Inactive" : "Active"}</b> ?
        </ModalBody>
        <ModalFooter>
          <Button variant="success" onClick={changeRoleStatus}>Yes</Button>
          <Button variant="secondary" onClick={closeRoleStatusDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default UserDetail;
