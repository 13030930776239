import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "@layouts/Header";
import React from "react";
import {
  Col,
  Container,
  Row
} from "react-bootstrap";

const CareGiverServiceAppliedDetail = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={12}></Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">User management</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">User detail: ...</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Caregiver service applied</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Service applied detail: ...
                </li>
              </ol>
            </nav>
          </Row>

          <Row className="mt-3">
            <b className="fs-4">Service Applied: ...</b>
          </Row>

          <Row>
            <Col md={12} className="text-end">
              <div>
                <button className="btn btn-success">Approve</button>
                <button className="btn btn-danger ms-2">Reject</button>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <div className="card">
                <div className="card-header">
                  <b>Basic Information</b>
                </div>
                <div className="card-body">
                  <a
                    href="#"
                    style={{ position: "absolute", right: "5px", top: "5px" }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </a>
                  <p>...</p>
                </div>
              </div>

              <div className="card mt-5">
                <div className="card-header">
                  <b>Skills Information</b>
                </div>
                <div className="card-body">
                  <a
                    href="#"
                    style={{ position: "absolute", right: "5px", top: "5px" }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </a>
                  <p>...</p>
                </div>
              </div>

              <div className="card mt-5">
                <div className="card-header">
                  <b>Assessment Information</b>
                </div>
                <div className="card-body">
                  <a
                    href="#"
                    style={{ position: "absolute", right: "5px", top: "5px" }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </a>
                  <p>...</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CareGiverServiceAppliedDetail;
